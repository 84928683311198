import * as React from "react"
import { SVGProps } from "react"

const PlanningIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x="1"
			y="2.99976"
			width="18"
			height="16"
			rx="2.07407"
			stroke="#7D828C"
			strokeWidth="1.84809"
			strokeLinecap="round"
		/>
		<path
			d="M11.0102 4.36038C11.0102 4.85249 10.6112 5.25142 10.1191 5.25142C9.62703 5.25142 9.2281 4.85249 9.2281 4.36038L11.0102 4.36038ZM9.2281 1.68726C9.2281 1.19515 9.62703 0.796214 10.1191 0.796214C10.6112 0.796214 11.0102 1.19515 11.0102 1.68726L9.2281 1.68726ZM9.2281 4.36038L9.2281 1.68726L11.0102 1.68726L11.0102 4.36038L9.2281 4.36038Z"
			fill="#7D828C"
		/>
		<path
			d="M16.3569 4.36038C16.3569 4.85249 15.9579 5.25142 15.4658 5.25142C14.9737 5.25142 14.5748 4.85249 14.5748 4.36038L16.3569 4.36038ZM14.5748 1.68726C14.5748 1.19515 14.9737 0.796214 15.4658 0.796214C15.9579 0.796214 16.3569 1.19515 16.3569 1.68726L14.5748 1.68726ZM14.5748 4.36038L14.5748 1.68726L16.3569 1.68726L16.3569 4.36038L14.5748 4.36038Z"
			fill="#7D828C"
		/>
		<path
			d="M5.66399 4.36038C5.66399 4.85249 5.26506 5.25142 4.77295 5.25142C4.28084 5.25142 3.88191 4.85249 3.88191 4.36038L5.66399 4.36038ZM3.88191 1.68726C3.88191 1.19515 4.28084 0.796214 4.77295 0.796214C5.26506 0.796214 5.66399 1.19515 5.66399 1.68726L3.88191 1.68726ZM3.88191 4.36038L3.88191 1.68726L5.66399 1.68726L5.66399 4.36038L3.88191 4.36038Z"
			fill="#7D828C"
		/>
		<path
			d="M4.45349 14.7308L7.83082 11.3535L10.3638 13.8865L14.1944 10.1237"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.1116 8.89253H15.489V12.2699"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default PlanningIcon
