import * as React from "react"
import { SVGProps } from "react"

const DishesGroupIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.68701 11.4939V10.4939C1.39306 10.4939 1.114 10.6232 0.923992 10.8475C0.733989 11.0718 0.652293 11.3683 0.700617 11.6583L1.68701 11.4939ZM18.3132 11.4939L19.2996 11.6583C19.3479 11.3683 19.2662 11.0718 19.0762 10.8475C18.8862 10.6232 18.6072 10.4939 18.3132 10.4939V11.4939ZM1.68701 12.4939H18.3132V10.4939H1.68701V12.4939ZM17.3268 11.3295L17.1901 12.1495L19.1629 12.4783L19.2996 11.6583L17.3268 11.3295ZM13.2565 15.4818H6.74368V17.4818H13.2565V15.4818ZM2.81008 12.1495L2.67341 11.3295L0.700617 11.6583L0.837291 12.4783L2.81008 12.1495ZM6.74368 15.4818C4.79426 15.4818 3.13056 14.0724 2.81008 12.1495L0.837291 12.4783C1.3185 15.3656 3.81658 17.4818 6.74368 17.4818V15.4818ZM17.1901 12.1495C16.8697 14.0724 15.206 15.4818 13.2565 15.4818V17.4818C16.1836 17.4818 18.6817 15.3656 19.1629 12.4783L17.1901 12.1495Z"
			fill="#7D828C"
		/>
		<path
			d="M1.68701 6.50562V5.50562C1.39306 5.50562 1.114 5.63495 0.923992 5.85924C0.733989 6.08353 0.652293 6.38006 0.700617 6.67001L1.68701 6.50562ZM18.3132 6.50562L19.2996 6.67001C19.3479 6.38006 19.2662 6.08353 19.0762 5.85924C18.8862 5.63495 18.6072 5.50562 18.3132 5.50562V6.50562ZM1.68701 7.50562H18.3132V5.50562H1.68701V7.50562ZM17.3268 6.34122L17.1901 7.16127L19.1629 7.49006L19.2996 6.67001L17.3268 6.34122ZM13.2565 10.4935H6.74368V12.4935H13.2565V10.4935ZM2.81008 7.16127L2.67341 6.34122L0.700617 6.67001L0.837291 7.49006L2.81008 7.16127ZM6.74368 10.4935C4.79426 10.4935 3.13056 9.08417 2.81008 7.16127L0.837291 7.49006C1.3185 10.3773 3.81658 12.4935 6.74368 12.4935V10.4935ZM17.1901 7.16127C16.8697 9.08417 15.206 10.4935 13.2565 10.4935V12.4935C16.1836 12.4935 18.6817 10.3773 19.1629 7.49006L17.1901 7.16127Z"
			fill="#7D828C"
		/>
		<path
			d="M1.68701 1.51782V0.517822C1.39306 0.517822 1.114 0.647156 0.923992 0.871447C0.733989 1.09574 0.652293 1.39227 0.700617 1.68222L1.68701 1.51782ZM18.3132 1.51782L19.2996 1.68222C19.3479 1.39227 19.2662 1.09574 19.0762 0.871447C18.8862 0.647156 18.6072 0.517822 18.3132 0.517822V1.51782ZM1.68701 2.51782H18.3132V0.517822H1.68701V2.51782ZM17.3268 1.35343L17.1901 2.17348L19.1629 2.50227L19.2996 1.68222L17.3268 1.35343ZM13.2565 5.50574H6.74368V7.50574H13.2565V5.50574ZM2.81008 2.17348L2.67341 1.35343L0.700617 1.68222L0.837291 2.50227L2.81008 2.17348ZM6.74368 5.50574C4.79426 5.50574 3.13056 4.09638 2.81008 2.17348L0.837291 2.50227C1.3185 5.38955 3.81658 7.50574 6.74368 7.50574V5.50574ZM17.1901 2.17348C16.8697 4.09638 15.206 5.50574 13.2565 5.50574V7.50574C16.1836 7.50574 18.6817 5.38955 19.1629 2.50227L17.1901 2.17348Z"
			fill="#7D828C"
		/>
	</svg>
)

export default DishesGroupIcon
