import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import ProductPresentation from '../pagesSections/menu/ProductPresentation'
import NomenclatureFeatures from '../pagesSections/menu/NomenclatureFeatures'
import TerminalFeatures from '../pagesSections/menu/TerminalFeatures'
import AnalyticsSection from '../pagesSections/menu/AnalyticsSection'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import { getMenuMeta } from '../pagesSections/menu/meta'

import Advantages from '../components/_V2/Advantages'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import BusinessTypes from '../components/_V2/BusinessTypes'

import { getAdvantagesSlides } from '../pages-data/_V2/menu/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import { getData } from '../pages-data/_V2/menu/FrenchiseFeature/frenchiseFeature'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/menu.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageMenu() {
  const localizationContext = useLocalizationContext();
  const data = getData(localizationContext.locale);
  const metaInfo = getMenuMeta(localizationContext.locale);

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={metaInfo.canonical}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        hideImages={false}
        className={classNames(
				  pageStyles.pageSection,
				  styles.menuPage__advantages,
        )}
      />

      <TerminalFeatures className={pageStyles.pageSection} />

      <AnalyticsSection className={pageStyles.pageSection} />

      <NomenclatureFeatures className={pageStyles.pageSection} />

      <SimpleFeatures data={data} className={pageStyles.pageSection} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm className={pageStyles.pageSection} isFry={false} />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessTypes(localizationContext.locale)}
        className={classNames(pageStyles.pageSection)}
      />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
