import React from "react"

import TabsFeatures from "../../../components/_V2/TabsFeatures"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function NomenclatureFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale)

	return (
		<TabsFeatures
			key={localizationContext.locale}
			data={data}
			className={props.className}
			rootClass={styles.nomenclatureFeatures}
			containerClass={styles.nomenclatureFeatures__container}
			wrapperClass={styles.nomenclatureFeatures__wrapper}
			additionalFeaturesListClass={styles.nomenclatureFeatures__featuresList}
			sliderClass={styles.nomenclatureFeatures__sliderClass}
			theme={"purple-light"}
		/>
	)
}
