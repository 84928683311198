import React from "react"

import { getData } from "./data"
import FullScreenFeatures from "../../../components/_V2/FullScreenFeatures"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function TerminalFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	return (
		<FullScreenFeatures
			key={localizationContext.locale}
			className={props.className}
			data={data.features}
			theme={"greenLight"}
			mediaContainerOverflowVisible
		/>
	)
}
