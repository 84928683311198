import * as React from "react"
import { SVGProps } from "react"

const ModificatorsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8 10.9998H16V8.99976H8V10.9998ZM16 10.9998V20.9998H18V10.9998H16ZM16 20.9998H8V22.9998H16V20.9998ZM8 20.9998V10.9998H6V20.9998H8ZM8 20.9998L8 20.9998H6C6 22.1043 6.89543 22.9998 8 22.9998V20.9998ZM16 20.9998L16 20.9998V22.9998C17.1046 22.9998 18 22.1043 18 20.9998H16ZM16 10.9998L16 10.9998H18C18 9.89519 17.1046 8.99976 16 8.99976V10.9998ZM8 8.99976C6.89543 8.99976 6 9.89519 6 10.9998H8L8 10.9998V8.99976Z"
			fill="#7D828C"
		/>
		<path
			d="M8 9.99976V6.99976H16V9.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 6.99976L11 1.99976H13L14 6.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 17.7498C12.9665 17.7498 13.75 16.9663 13.75 15.9998C13.75 15.0333 12.9665 14.2498 12 14.2498C11.0335 14.2498 10.25 15.0333 10.25 15.9998C10.25 16.9663 11.0335 17.7498 12 17.7498Z"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
	</svg>
)

export default ModificatorsIcon
