import classNames from "classnames"
import React, { useState } from "react"

import styles from "./styles.module.scss"

import "swiper/css"
import "swiper/scss/effect-fade"

interface ImageDataItem {
	name: string
	img: JSX.Element
	badge?: JSX.Element
	label?: JSX.Element | string
}

interface Props {
	className?: string
	tabClass?: string
	selectedTabClass?: string
	imagesData: ImageDataItem[]
}

export default function ImageTabs(props: Props) {
	const [selectedImage, setSelectedImage] = useState(props.imagesData[0])

	const onClickFeature = (image: ImageDataItem) => {
		setSelectedImage(image)
	}

	return (
		<section className={classNames(styles.imageTabs, props.className)}>
			{props.imagesData.map(image => (
					<figure
						key={image.name}
						className={classNames(styles.imageTabs__image, {
							[styles.imageTabs__imageSelected]: image === selectedImage,
						})}
					>
						{image.img}

						{image.badge && (
							<div className={styles.imageTabs__badge}>{image.badge}</div>
						)}
					</figure>
			))}

			<ul className={styles.imageTabs__tabs}>
				{props.imagesData.map(image => (
					<li
						key={image.name}
						className={classNames(styles.imageTabs__tab, props.tabClass, {
							[classNames(
								styles.imageTabs__tabSelected,
								props.selectedTabClass
							)]: image === selectedImage,
						})}
						onClick={() => onClickFeature(image)}
					>
						{image.name}
					</li>
				))}
			</ul>

			{props.imagesData.map(
				image =>
					image === selectedImage &&
					image.label && (
						<noindex>
							<p className={styles.imageTabs__label}>{image.label}</p>
						</noindex>
					)
			)}
		</section>
	)
}
