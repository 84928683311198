import * as React from "react"
import { SVGProps } from "react"

const NoteIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path
			d="M18.743 5.375V4.49998C18.743 3.39542 17.8476 2.5 16.7431 2.5H5.74315C4.63859 2.5 3.74316 3.39542 3.74316 4.49998V19.4999C3.74316 20.6044 4.63859 21.4999 5.74315 21.4999H16.7431C17.8476 21.4999 18.743 20.6044 18.743 19.4999V18.75"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.83398 7.48535H16.6362L18.7383 5.3833"
			stroke="#5F47BF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.1895 8.03613L23.1575 10.1988L15.1471 17.4883L12.532 17.8913L13.1791 15.3257L21.1895 8.03613Z"
			stroke="#5F47BF"
			strokeWidth="1.89163"
			strokeLinejoin="round"
		/>
	</svg>
)

export default NoteIcon
