import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./style.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 488,
					tabName: "Аналитика",
					desktopTitle: "Пользуйся спросом",
					desktopContent: (
						<p>
							Оптимизируй меню с&nbsp;помощью ABC анализа. Увеличивай прибыль
							заведения за&nbsp;счёт идеального соотношения блюд
							по&nbsp;маржинальности, оборачиваемости и&nbsp;популярности.
						</p>
					),
					mobileContent: (
						<p>
							Оптимизируй меню с помощью <br />
							ABC анализа. Увеличивай <br />
							прибыль заведения за счёт <br />
							идеального соотношения блюд <br />
							по маржинальности, <br />
							оборачиваемости и <br />
							популярности.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-1_kz.png"
								alt={"abc-анализ в ресторане"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 488,
					tabName: "Управление ценами",
					desktopTitle: "Это закон рынка",
					desktopContent: (
						<p>
							Считай фудокст и&nbsp;автоматизируй контроль <br />
							себестоимости блюд и&nbsp;закупок. Включай <br />
							правильные цены для каждого заведения <br />
							за&nbsp;пару кликов.
						</p>
					),
					tabletContent: (
						<p>
							Считай фудокст и автоматизируй контроль себестоимости блюд и закупок. Включай правильные цены для <br />
							каждого заведения за пару кликов.
						</p>
					),
					mobileContent: (
						<p>
							Считай фудокст и автоматизируй <br />
							контроль себестоимости блюд и <br />
							закупок. Включай правильные <br />
							цены для каждого заведения <br />
							за пару кликов.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-2_kz.png"
								alt={"контроль себестоимости блюд"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 488,
					tabName: "Техкарты",
					desktopTitle: "Системный подход",
					desktopContent: (
						<p>
							Заполни техкарту на блюдо, чтобы система <br />
							автоматически рассчитала себестоимость, <br />
							расход при обработке ингредиентов, КБЖУ.
						</p>
					),
					tabletContent: (
						<p>
							Заполни техкарту на блюдо, чтобы система автоматически рассчитала себестоимость, <br />
							расход при обработке ингредиентов, КБЖУ.
						</p>
					),
					mobileContent: (
						<p>
							Заполни техкарту на блюдо, чтобы <br />
							система автоматически <br />
							рассчитала себестоимость, <br />
							расход при обработке <br />
							ингредиентов, КБЖУ.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-3_kz.png"
								alt={"технологические карты блюд"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 488,
					tabName: "Аналитика",
					desktopTitle: "Сұранысты пайдаланыңыз",
					desktopContent: (
						<p>
							АВС-талдау көмегімен мәзірді оңтайландырыңыз. Маржиналдық, айналымдылық және танымалдылық
							бойынша тағамдардың мінсіз арақатынасы есебінен мекеменің пайдасын арттырыңыз.
						</p>
					),
					mobileContent: (
						<p>
							АВС-талдау көмегімен мәзірді оңтайландырыңыз. Маржиналдық, айналымдылық және
							танымалдылық бойынша тағамдардың мінсіз арақатынасы есебінен мекеменің пайдасын арттырыңыз.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-1_kz.png"
								alt={"abc-анализ в ресторане"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 488,
					tabName: "Бағаларды басқару",
					desktopTitle: "Бұл нарық заңы",
					desktopContent: (
						<p>
							Фудокостты есептеңіз және тағамдардың өзіндік құнын және сатып алуды бақылауды автоматтандырыңыз. Әр мекем үшін дұрыс бағаларды екі кликпен қосыңыз.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-2_kz.png"
								alt={"контроль себестоимости блюд"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 488,
					tabName: "Техкарталар",
					desktopTitle: "Жүйелік тәсілдеме",
					desktopContent: (
						<p>
							Жүйе ингредиенттерді, КАМК өңдеу кезінде өзіндік құнды, шығысты автоматты түрде есептеуі үшін тағамға техкартаны толтырыңыз.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-3_kz.png"
								alt={"технологические карты блюд"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 488,
					tabName: "Аналитика",
					desktopTitle: "Пользуйся спросом",
					desktopContent: (
						<p>
							Оптимизируй меню с помощью ABC анализа. <br />
							Увеличивай прибыль заведения за счёт идеального <br />
							соотношения блюд по маржинальности, <br />
							оборачиваемости и популярности.
						</p>
					),
					tabletContent: (
						<p>
							Оптимизируй меню с помощью ABC анализа. Увеличивай прибыль заведения за счёт идеального соотношения <br />
							блюд по маржинальности, оборачиваемости и популярности.
						</p>
					),
					mobileContent: (
						<p>
							Оптимизируй меню с помощью <br />
							ABC анализа. Увеличивай <br />
							прибыль заведения за счёт <br />
							идеального соотношения блюд <br />
							по маржинальности, <br />
							оборачиваемости и <br />
							популярности.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-1_by.png"
								alt={"abc-анализ в ресторане"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 488,
					tabName: "Управление ценами",
					desktopTitle: "Выбирай \nправильные цены",
					desktopContent: (
						<p>
							Считай фудкост и автоматизируй контроль <br />
							себестоимости блюд и закупок. <br />
							Включай правильные цены <br />
							для каждого заведения за пару кликов.
						</p>
					),
					tabletContent: (
						<p>
							Считай фудокст и автоматизируй контроль себестоимости блюд и закупок. Включай правильные цены для <br />
							каждого заведения за пару кликов.
						</p>
					),
					mobileContent: (
						<p>
							Считай фудокст и автоматизируй <br />
							контроль себестоимости блюд и <br />
							закупок. Включай правильные <br />
							цены для каждого заведения <br />
							за пару кликов.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-2_by.png"
								alt={"контроль себестоимости блюд"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 488,
					tabName: "Техкарты",
					desktopTitle: "Системно и точно",
					mobileTitle: "Системно\nи точно",
					desktopContent: (
						<p>
							Заполни техкарту на блюдо, чтобы система <br />
							автоматически рассчитала себестоимость, <br />
							расход при обработке ингредиентов и КБЖУ.
						</p>
					),
					tabletContent: (
						<p>
							Заполни техкарту на блюдо, чтобы система автоматически рассчитала себестоимость, <br />
							расход при обработке ингредиентов, КБЖУ.
						</p>
					),
					mobileContent: (
						<p>
							Заполни техкарту на блюдо, чтобы <br />
							система автоматически <br />
							рассчитала себестоимость, <br />
							расход при обработке <br />
							ингредиентов, КБЖУ.
						</p>
					),
					mediaContent: (
						<div className={styles.image__container}>
							<StaticImage
								className={styles.image}
								src="./assets/menu-analytics-3_by.png"
								alt={"технологические карты блюд"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 488,
				tabName: "Аналитика",
				desktopTitle: "Пользуйся спросом",
				desktopContent: (
					<p>
						Оптимизируй меню с&nbsp;помощью ABC анализа. Увеличивай прибыль
						заведения за&nbsp;счёт идеального соотношения блюд
						по&nbsp;маржинальности, оборачиваемости и&nbsp;популярности.
					</p>
				),
				mediaContent: (
					<div className={styles.image__container}>
						<StaticImage
							className={styles.image}
							src="./assets/menu-analytics-1.png"
							alt={"abc-анализ в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
			{
				desktopContentWidth: 488,
				tabName: "Управление ценами",
				desktopTitle: "Это закон рынка",
				desktopContent: (
					<p>
						Считай фудокст и&nbsp;автоматизируй контроль себестоимости блюд
						и&nbsp;закупок. Включай правильные цены для каждого заведения
						за&nbsp;пару кликов.
					</p>
				),
				mediaContent: (
					<div className={styles.image__container}>
						<StaticImage
							className={styles.image}
							src="./assets/menu-analytics-2.png"
							alt={"контроль себестоимости блюд"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
			{
				desktopContentWidth: 488,
				tabName: "Техкарты",
				desktopTitle: "Системный подход",
				desktopContent: (
					<p>
						Заполни техкарту на&nbsp;блюдо, чтобы система автоматически рассчитала
						себестоимость, расход при обработке ингредиентов, КБЖУ.
					</p>
				),
				mediaContent: (
					<div className={styles.image__container}>
						<StaticImage
							className={styles.image}
							src="./assets/menu-analytics-3.png"
							alt={"технологические карты блюд"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}
