import { Locales } from "../../../localization/types";
import PresentationImg from "../ProductPresentation/assets/menu-presentation.png";
import PresentationImgKz from "../ProductPresentation/assets/menu-presentation_kz.png";
import PresentationImgBy from "../ProductPresentation/assets/menu-presentation_by.png";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getMenuMeta = (locale: Locales) => {
	switch (locale) {
		case "ru-KZ":
			return {
				title: "Меню и технологические карты в системе автоматизации ресторана | Quick Resto Казахстан",
				metaTags: [
					{
						name: "description",
						content:
							"Работа с меню и техкартами в системе Quick Resto → Расчёт себестоимости блюд и пищевой ценности → Контроль фудкоста и потерь при обработке ингредиентов → Управляй маржинальностью меню и зарабатывай больше. Автоматизируй бизнес в Казахстане",
					},
					{
						property: "og:url",
						content: `${externalLinks.quickrestoKZ.href}menu/`,
					},
					{
						property: "og:title",
						content:
							"Меню и технологические карты в системе автоматизации ресторана | Quick Resto Казахстан",
					},
					{
						property: "og:description",
						content:
							"Работа с меню и техкартами в системе Quick Resto → Расчёт себестоимости блюд и пищевой ценности → Контроль фудкоста и потерь при обработке ингредиентов → Управляй маржинальностью меню и зарабатывай больше. Автоматизируй бизнес в Казахстане",
					},
					{
						property: "og:type",
						content: "website",
					},
					{
						property: "og:image",
						content: `${externalLinks.quickrestoKZ.href}${PresentationImgKz}`,
					},
				],
				canonical: `${externalLinks.quickrestoKZ.href}menu/`,
			};
		case "ru-BY":
			return {
				title: "Меню и технологические карты в системе автоматизации ресторана | Quick Resto Беларусь",
				metaTags: [
					{
						name: "description",
						content:
							"Работа с меню и техкартами в системе Quick Resto → Расчёт себестоимости блюд и пищевой ценности → Контроль фудкоста и потерь при обработке ингредиентов → Управляй маржинальностью меню и зарабатывай больше. Автоматизируй бизнес в Беларуси",
					},
					{
						property: "og:url",
						content: `${externalLinks.quickrestoBY.href}menu/`,
					},
					{
						property: "og:title",
						content:
							"Меню и технологические карты в системе автоматизации ресторана | Quick Resto Беларусь",
					},
					{
						property: "og:description",
						content:
							"Работа с меню и техкартами в системе Quick Resto → Расчёт себестоимости блюд и пищевой ценности → Контроль фудкоста и потерь при обработке ингредиентов → Управляй маржинальностью меню и зарабатывай больше. Автоматизируй бизнес в Беларуси",
					},
					{
						property: "og:type",
						content: "website",
					},
					{
						property: "og:image",
						content: `${externalLinks.quickrestoBY.href}${PresentationImgBy}`,
					},
				],
				canonical: `${externalLinks.quickrestoBY.href}menu/`,
			};
		default:
			return {
				title: "Меню и технологические карты в системе автоматизации ресторана | Quick Resto",
				metaTags: [
					{
						name: "description",
						content:
							"Работа с меню и техкартами в системе Quick Resto  → Расчёт себестоимости блюд и пищевой ценности → Контроль фудкоста и потерь при обработке ингредиентов → Управляй маржинальностью меню и зарабатывай больше",
					},
					{
						property: "og:url",
						content: `${externalLinks.quickrestoRU.href}menu/`,
					},
					{
						property: "og:title",
						content:
							"Меню и технологические карты в системе автоматизации ресторана | Quick Resto",
					},
					{
						property: "og:description",
						content:
							"Работа с меню и техкартами в системе Quick Resto  → Расчёт себестоимости блюд и пищевой ценности → Контроль фудкоста и потерь при обработке ингредиентов → Управляй маржинальностью меню и зарабатывай больше",
					},
					{
						property: "og:image",
						content: `${externalLinks.quickrestoRU.href}${PresentationImg}`,
					},
					{
						property: "og:type",
						content: "website",
					},
				],
				canonical: `${externalLinks.quickrestoRU.href}menu/`,
			};
	}
};
