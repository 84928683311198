import * as React from "react"
import { SVGProps } from "react"

const TechCardsIcon2 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M19 7.01636V1.99976C19 1.44747 18.5523 0.999756 18 0.999756H2C1.44772 0.999756 1 1.44747 1 1.99976V15.9998C1 16.552 1.44772 16.9998 2 16.9998H4.93602"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9.02539 4.99976L3.99976 4.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.5127 8.99976L3.99988 8.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.8374 12.9998L3.9998 12.9998"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 14.6168C8 13.127 8.57947 11.6983 9.61092 10.6449C10.6424 9.59155 12.0413 8.99976 13.5 8.99976C14.9587 8.99976 16.3576 9.59155 17.3891 10.6449C18.4205 11.6983 19 13.127 19 14.6168"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.4468 8.99976V6.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default TechCardsIcon2
