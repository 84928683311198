import React from "react"
import classNames from "classnames"

import { getData } from "./data"
import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"
import styles from "./style.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function AnalyticsSection(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<div className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				key={localizationContext.locale}
				className={styles.container}
				theme="orange-yellow"
				features={getData(localizationContext.locale).features}
				direction={FEATURE_GALLERY_DIRECTION.REVERSE}
			/>
		</div>
	)
}
