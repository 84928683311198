import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Locales } from "../../../localization/types";


export const getAdvantagesSlides = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				title: "Техкарты в два счёта",
				description:
					"Заполни их так, как тебе привычно. Расчёт себестоимости порций, их пищевая ценность и списание со склада происходят автоматически.",
				img: (
					<StaticImage
						src="./assets/me-advantages-1.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				title: "Финансы — наглядно",
				description:
					"Контролируй фудкост и потери при обработке ингредиентов. Система покажет в отчетах реальную прибыль.",
				img: (
					<StaticImage
						src="./assets/me-advantages-2.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				title: "Еще больше золота",
				description:
					"Сфокусируй сотрудника на допродажах: введи топпинги и модификаторы блюд. Составь маржинальные комбо-наборы и бизнес ланчи. ",
				img: (
					<StaticImage
						src="./assets/me-advantages-3.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: "Техкарталар әп-сәтте дайын",
				description:
					"Оларды әдеттегідей толтырыңыз. Порциялардың өзіндік құнын есептеу, олардың тағамдық құндылығы және қоймадан есептен шығару автоматты түрде болады.",
				img: (
					<StaticImage
						src="./assets/me-advantages-1.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				title: "Қаржы - көрнекі түрде",
				description:
					"Ингредиеттерді өңдеу кезінде фудкостты және шығындарды бақылаңыз. Жүйе есептерде нақты пайданы көрсетеді.",
				img: (
					<StaticImage
						src="./assets/me-advantages-2.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				title: "Одан да көп алтын",
				description:
					"Қызметкердің қосымша сатуға аударыңыз: топпингтер мен тағамдар модификаторларын енгізіңіз. Маржиналдық комбо-жинақтар мен бизнес-ланчтарды жасаңыз.",
				img: (
					<StaticImage
						src="./assets/me-advantages-3.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				title: "Техкарты — в два счёта",
				description:
					"Заполни их так, как тебе привычно. Расчёт себестоимости порций и пищевой ценности, списание со склада происходят автоматом.",
				img: (
					<StaticImage
						src="./assets/me-advantages-1.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				title: "Финансы — наглядно",
				description:
					"Контролируй фудкост и потери при обработке ингредиентов. Система покажет в отчетах реальную прибыль.",
				img: (
					<StaticImage
						src="./assets/me-advantages-2.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				title: "Рост — быстрее",
				description:
					"Сфокусируй сотрудника на допродажах: введи топпинги и модификаторы блюд. Составь маржинальные комбо-наборы и бизнес ланчи. ",
				img: (
					<StaticImage
						src="./assets/me-advantages-3.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	return [
		{
			title: "Техкарты в два счёта",
			description:
				"Заполни их так, как тебе привычно. Расчёт себестоимости порций, их пищевая ценность и списание со склада происходят автоматически.",
			img: (
				<StaticImage
					src="./assets/me-advantages-1.png"
					alt="Работает на всех устройствах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			title: "Финансы — наглядно",
			description:
				"Контролируй фудкост и потери при обработке ингредиентов. Система покажет в отчетах реальную прибыль.",
			img: (
				<StaticImage
					src="./assets/me-advantages-2.png"
					alt="Работает на всех устройствах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			title: "Еще больше золота",
			description:
				"Сфокусируй сотрудника на допродажах: введи топпинги и модификаторы блюд. Составь маржинальные комбо-наборы и бизнес ланчи. ",
			img: (
				<StaticImage
					src="./assets/me-advantages-3.png"
					alt="Работает на всех устройствах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	]
}
