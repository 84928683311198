import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

import styles from "./styles.module.scss"
import IngredientsIcon from "../../../assets/_V2/svg/IngredientsIcon"
import SemiFinishedProductsIcon from "../../../assets/_V2/svg/SemiFinishedProductsIcon"
import DishesIcon from "../../../assets/_V2/svg/DishesIcon"
import DishesGroupIcon from "../../../assets/_V2/svg/DishesGroupIcon"
import ModificatorsIcon from "../../../assets/_V2/svg/ModificatorsIcon"
import TechCardsIcon from "../../../assets/_V2/svg/TechCardsIcon"
import PlanningIcon from "../../../assets/_V2/svg/PlanningIcon"
import NoteIcon from "../../../assets/_V2/svg/NoteIcon"
import TechCardIcon2 from "../../../assets/_V2/svg/TechCardIcon2"
import ImageTabs from "../../../components/_V2/ImageTabs"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: "Обратная сторона меню",
			category: "номенклатура",
			headerPlacement: "left",
			features: [
				{
					icon: <IngredientsIcon />,
					name: "Ингредиенты",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui1_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "С синхронизацией со складом",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize1
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								С синхронизацией
								<br />
								со складом
							</div>
						</div>
					),
				},
				{
					icon: <SemiFinishedProductsIcon />,
					name: "Полуфабрикаты",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui2_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "С нормой закладки",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize2
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								С нормой <br />
								закладки
							</div>
						</div>
					),
				},
				{
					icon: <DishesIcon />,
					name: "Блюда",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui3_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Содержит фото и рецепты",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize3
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Содержит фото и рецепты
							</div>
						</div>
					),
				},
				{
					icon: <DishesGroupIcon />,
					name: "Группировка блюд",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui4_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Для удобства продаж",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize1
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Для удобства <br />
								продаж
							</div>
						</div>
					),
				},
				{
					icon: <ModificatorsIcon />,
					name: "Модификаторы и топпинги",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui5_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Быстрая настройка блюда",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize4
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Быстрая настройка блюда
							</div>
						</div>
					),
				},
				{
					icon: <TechCardsIcon />,
					name: "Техкарты",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui6_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Заполняются быстро, соответствуют стандартам",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize5
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Заполняются быстро, <br />
								соответствуют
								<br />
								стандартам
							</div>
						</div>
					),
				},
				{
					icon: <PlanningIcon />,
					name: "Планирование продаж",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui7-default_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Подсказки, старт-листы, маржинальные позиции, новинки",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize6
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Подсказки,
								<br />
								старт-листы, маржинальные
								<br />
								позиции,
								<br />
								новинки
							</div>
						</div>
					),
				},
				{
					icon: <TechCardIcon2 />,
					name: "Версионирование техкарт",
					mediaContent: (
						<ImageTabs
							tabClass={styles.nomenclatureFeatures__tabClass}
							selectedTabClass={styles.nomenclatureFeatures__selectedTabClass}
							imagesData={[
								{
									name: "Базовая версия",
									img: (
										<StaticImage
											className={styles.nomenclatureFeatures__image}
											src="./assets/ui8-base_kz.png"
											alt="версионирование техкарт"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
									badge: (
										<div
											className={classNames(
												styles.nomenclatureFeaturesBadge,
												styles.nomenclatureFeaturesBadgeSize5
											)}
										>
											<div className={styles.nomenclatureFeaturesBadge__content}>
												<NoteIcon />
												Когда нужно изменить
												<br />
												ингредиент или его
												<br />
												количество
											</div>
										</div>
									),
									label: "Когда нужно изменить ингредиент или его количество",
								},
								{
									name: "Новая версия",
									img: (
										<StaticImage
											className={styles.nomenclatureFeatures__image}
											src="./assets/ui8_kz.png"
											alt="версионирование техкарт"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
									badge: (
										<div
											className={classNames(
												styles.nomenclatureFeaturesBadge,
												styles.nomenclatureFeaturesBadgeSize5
											)}
										>
											<div className={styles.nomenclatureFeaturesBadge__content}>
												<NoteIcon />
												Используй версию
												<br />
												техкарты с<br />
												определенной
												<br />
												даты
												<br />
											</div>
										</div>
									),
									label: "Используй версию техкарты с определенной даты",
								},
							]}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Мәзірдің теріс жағы",
			category: "номенклатура",
			headerPlacement: "left",
			features: [
				{
					icon: <IngredientsIcon />,
					name: "Ингредиенттер",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui1_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Қоймамен синхрондаумен",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize1
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Қоймамен синхрондаумен
							</div>
						</div>
					),
				},
				{
					icon: <SemiFinishedProductsIcon />,
					name: "Жартылай дайын өнімдер",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui2_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Салу нормасымен",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize2
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Салу нормасымен
							</div>
						</div>
					),
				},
				{
					icon: <DishesIcon />,
					name: "Тағамдар",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui3_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Құрамында фотосуреттер мен рецепттер бар",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize3
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Құрамында фотосуреттер мен рецепттер бар
							</div>
						</div>
					),
				},
				{
					icon: <DishesGroupIcon />,
					name: "Тағамдарды топтастыру",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui4_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Сату ыңғайлы болуы үшін",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize1
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Сату ыңғайлы болуы үшін
							</div>
						</div>
					),
				},
				{
					icon: <ModificatorsIcon />,
					name: "Модификаторлар мен топпингтер",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui5_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Тағамды тез теңшеу",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize4
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Тағамды тез теңшеу
							</div>
						</div>
					),
				},
				{
					icon: <TechCardsIcon />,
					name: "Техкарталар",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui6_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Тез толтырылады, стандарттарға сәйкес келеді",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize5
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Тез толтырылады, стандарттарға сәйкес келеді
							</div>
						</div>
					),
				},
				{
					icon: <PlanningIcon />,
					name: "Сатуды жоспарлау",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui7-default_kz.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Сыбырлар,  старт-листтер, маржиналдық позициялар, жаңалықтар",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize6
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Сыбырлар,  старт-листтер, маржиналдық позициялар, жаңалықтар
							</div>
						</div>
					),
				},
				{
					icon: <TechCardIcon2 />,
					name: "Техкарталардың нұсқаларын белгілеу",
					mediaContent: (
						<ImageTabs
							tabClass={styles.nomenclatureFeatures__tabClass}
							selectedTabClass={styles.nomenclatureFeatures__selectedTabClass}
							imagesData={[
								{
									name: "Негізгі нұсқа",
									img: (
										<StaticImage
											className={styles.nomenclatureFeatures__image}
											src="./assets/ui8-base_kz.png"
											alt="версионирование техкарт"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
									badge: (
										<div
											className={classNames(
												styles.nomenclatureFeaturesBadge,
												styles.nomenclatureFeaturesBadgeSize5
											)}
										>
											<div className={styles.nomenclatureFeaturesBadge__content}>
												<NoteIcon />
												Ингредиентті немесе оның санын өзгерту керек болғанда
											</div>
										</div>
									),
									label: "Ингредиентті немесе оның санын өзгерту керек болғанда",
								},
								{
									name: "Жаңа нұсқа",
									img: (
										<StaticImage
											className={styles.nomenclatureFeatures__image}
											src="./assets/ui8_kz.png"
											alt="версионирование техкарт"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
									badge: (
										<div
											className={classNames(
												styles.nomenclatureFeaturesBadge,
												styles.nomenclatureFeaturesBadgeSize5
											)}
										>
											<div className={styles.nomenclatureFeaturesBadge__content}>
												<NoteIcon />
												Техкартаның нұсқасын белгілі бір күннен бастап пайдаланыңыз
											</div>
										</div>
									),
									label: "Техкартаның нұсқасын белгілі бір күннен бастап пайдаланыңыз",
								},
							]}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: "Обратная сторона меню",
			category: "номенклатура",
			headerPlacement: "left",
			features: [
				{
					icon: <IngredientsIcon />,
					name: "Ингредиенты",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui1_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "С синхронизацией со складом",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize1
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								С синхронизацией
								<br />
								со складом
							</div>
						</div>
					),
				},
				{
					icon: <SemiFinishedProductsIcon />,
					name: "Полуфабрикаты",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui2_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "С нормой закладки",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize2
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								С нормой <br />
								закладки
							</div>
						</div>
					),
				},
				{
					icon: <DishesIcon />,
					name: "Блюда",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui3_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Содержит фото и рецепты",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize3
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Содержит фото и рецепты
							</div>
						</div>
					),
				},
				{
					icon: <DishesGroupIcon />,
					name: "Группировка блюд",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui4_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Для удобства продаж",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize1
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Для удобства <br />
								продаж
							</div>
						</div>
					),
				},
				{
					icon: <ModificatorsIcon />,
					name: "Модификаторы и топпинги",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui5_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Быстрая настройка блюда",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize4
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Быстрая настройка блюда
							</div>
						</div>
					),
				},
				{
					icon: <TechCardsIcon />,
					name: "Техкарты",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui6_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Заполняются быстро, соответствуют стандартам",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize5
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Заполняются быстро, <br />
								соответствуют
								<br />
								стандартам
							</div>
						</div>
					),
				},
				{
					icon: <PlanningIcon />,
					name: "Планирование продаж",
					mediaContent: (
						<>
							<StaticImage
								className={styles.nomenclatureFeatures__image}
								src="./assets/ui7-default_by.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</>
					),
					label: "Подсказки, старт-листы, маржинальные позиции, новинки",
					badge: (
						<div
							className={classNames(
								styles.nomenclatureFeaturesBadge,
								styles.nomenclatureFeaturesBadgeSize6
							)}
						>
							<div className={styles.nomenclatureFeaturesBadge__content}>
								<NoteIcon />
								Подсказки,
								<br />
								старт-листы, маржинальные
								<br />
								позиции,
								<br />
								новинки
							</div>
						</div>
					),
				},
				{
					icon: <TechCardIcon2 />,
					name: "Версионирование техкарт",
					mediaContent: (
						<ImageTabs
							tabClass={styles.nomenclatureFeatures__tabClass}
							selectedTabClass={styles.nomenclatureFeatures__selectedTabClass}
							imagesData={[
								{
									name: "Базовая версия",
									img: (
										<StaticImage
											className={styles.nomenclatureFeatures__image}
											src="./assets/ui8-base_by.png"
											alt="версионирование техкарт"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
									badge: (
										<div
											className={classNames(
												styles.nomenclatureFeaturesBadge,
												styles.nomenclatureFeaturesBadgeSize5
											)}
										>
											<div className={styles.nomenclatureFeaturesBadge__content}>
												<NoteIcon />
												Когда нужно изменить
												<br />
												ингредиент или его
												<br />
												количество
											</div>
										</div>
									),
									label: "Когда нужно изменить ингредиент или его количество",
								},
								{
									name: "Новая версия",
									img: (
										<StaticImage
											className={styles.nomenclatureFeatures__image}
											src="./assets/ui8_by.png"
											alt="версионирование техкарт"
											objectFit="contain"
											placeholder="blurred"
											quality={90}
										/>
									),
									badge: (
										<div
											className={classNames(
												styles.nomenclatureFeaturesBadge,
												styles.nomenclatureFeaturesBadgeSize5
											)}
										>
											<div className={styles.nomenclatureFeaturesBadge__content}>
												<NoteIcon />
												Используй версию
												<br />
												техкарты с<br />
												определенной
												<br />
												даты
												<br />
											</div>
										</div>
									),
									label: "Используй версию техкарты с определенной даты",
								},
							]}
						/>
					),
					// label: 'Используй версию техкарты с определенной даты',
					// badge: (
					//   <div
					//     className={classNames(
					//       styles.nomenclatureFeaturesBadge,
					//       styles.nomenclatureFeaturesBadgeSize5,
					//     )}
					//   >
					//     <div className={styles.nomenclatureFeaturesBadge__content}>
					//       <NoteIcon />
					//       Используй версию<br/>техкарты с<br/>определенной<br/>даты<br />
					//     </div>
					//   </div>
					// ),
				},
			],
		}
	}

	return {
		title: "Обратная сторона меню",
		category: "номенклатура",
		headerPlacement: "left",
		features: [
			{
				icon: <IngredientsIcon />,
				name: "Ингредиенты",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui1.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "С синхронизацией со складом",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize1
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							С синхронизацией
							<br />
							со складом
						</div>
					</div>
				),
			},
			{
				icon: <SemiFinishedProductsIcon />,
				name: "Полуфабрикаты",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui2.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "С нормой закладки",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize2
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							С нормой <br />
							закладки
						</div>
					</div>
				),
			},
			{
				icon: <DishesIcon />,
				name: "Блюда",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui3.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "Содержит фото и рецепты",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize3
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							Содержит фото и рецепты
						</div>
					</div>
				),
			},
			{
				icon: <DishesGroupIcon />,
				name: "Группировка блюд",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui4.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "Для удобства продаж",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize1
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							Для удобства <br />
							продаж
						</div>
					</div>
				),
			},
			{
				icon: <ModificatorsIcon />,
				name: "Модификаторы и топпинги",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui5.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "Быстрая настройка блюда",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize4
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							Быстрая настройка блюда
						</div>
					</div>
				),
			},
			{
				icon: <TechCardsIcon />,
				name: "Техкарты",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui6.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "Заполняются быстро, соответствуют ГОСТ",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize5
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							Заполняются быстро, <br />
							соответствуют
							<br />
							ГОСТ
						</div>
					</div>
				),
			},
			{
				icon: <PlanningIcon />,
				name: "Планирование продаж",
				mediaContent: (
					<>
						<StaticImage
							className={styles.nomenclatureFeatures__image}
							src="./assets/ui7-default.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</>
				),
				label: "Подсказки, старт-листы, маржинальные позиции, новинки",
				badge: (
					<div
						className={classNames(
							styles.nomenclatureFeaturesBadge,
							styles.nomenclatureFeaturesBadgeSize6
						)}
					>
						<div className={styles.nomenclatureFeaturesBadge__content}>
							<NoteIcon />
							Подсказки,
							<br />
							старт-листы, маржинальные
							<br />
							позиции,
							<br />
							новинки
						</div>
					</div>
				),
			},
			{
				icon: <TechCardIcon2 />,
				name: "Версионирование техкарт",
				mediaContent: (
					<ImageTabs
						tabClass={styles.nomenclatureFeatures__tabClass}
						selectedTabClass={styles.nomenclatureFeatures__selectedTabClass}
						imagesData={[
							{
								name: "Бэк-офис",
								img: (
									<StaticImage
										className={styles.nomenclatureFeatures__image}
										src="./assets/ui8-base.png"
										alt="версионирование техкарт"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
								badge: (
									<div
										className={classNames(
											styles.nomenclatureFeaturesBadge,
											styles.nomenclatureFeaturesBadgeSize5
										)}
									>
										<div className={styles.nomenclatureFeaturesBadge__content}>
											<NoteIcon />
											Когда нужно изменить
											<br />
											ингредиент или его
											<br />
											количество
										</div>
									</div>
								),
								label: "Когда нужно изменить ингредиент или его количество",
							},
							{
								name: "Терминал",
								img: (
									<StaticImage
										className={styles.nomenclatureFeatures__image}
										src="./assets/ui8.png"
										alt="версионирование техкарт"
										objectFit="contain"
										placeholder="blurred"
										quality={90}
									/>
								),
								badge: (
									<div
										className={classNames(
											styles.nomenclatureFeaturesBadge,
											styles.nomenclatureFeaturesBadgeSize5
										)}
									>
										<div className={styles.nomenclatureFeaturesBadge__content}>
											<NoteIcon />
											Используй версию
											<br />
											техкарты с<br />
											определенной
											<br />
											даты
											<br />
										</div>
									</div>
								),
								label: "Используй версию техкарты с определенной даты",
							},
						]}
					/>
				),
				// label: 'Используй версию техкарты с определенной даты',
				// badge: (
				//   <div
				//     className={classNames(
				//       styles.nomenclatureFeaturesBadge,
				//       styles.nomenclatureFeaturesBadgeSize5,
				//     )}
				//   >
				//     <div className={styles.nomenclatureFeaturesBadge__content}>
				//       <NoteIcon />
				//       Используй версию<br/>техкарты с<br/>определенной<br/>даты<br />
				//     </div>
				//   </div>
				// ),
			},
		],
	}
}
