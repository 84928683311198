import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

import backdropIconsStyles from "./backdropIconsStyles.module.scss"
import mediaContent from "./mediaContent.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					tabName: "Всё для роста",
					desktopTitle: "Ловко ты это продал!",
					desktopContent: (
						<p>
							Используй старт-листы для продвижения самых маржинальных позиций.<br />
							Подсказки на&nbsp;терминале напомнят кассиру предложить топпинги и&nbsp;модификаторы.
						</p>
					),
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container1
							)}
						>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image1)}
								src="./assets/menu-terminal-sell_kz.png"
								alt={"меню кафе на кассовом терминале"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
				{
					tabName: "Ускорение работы",
					desktopTitle: "Всегда лёгкий выбор",
					desktopContent:
						"Формируй заказы быстрее — сгруппируй блюда по типам и добавь их фото. Подключи \nэлектронные весы — учет продаж на развес или частями будет вестись автоматически.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container2
							)}
						>
							<StaticImage
								className={classNames(
									mediaContent.image,
									mediaContent.image2,
									mediaContent.image__desktop
								)}
								src="./assets/menu-terminal-speed-up_kz.png"
								alt={"группы блюд в меню"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(
									mediaContent.image,
									mediaContent.image2,
									mediaContent.image__tablet
								)}
								src="./assets/menu-terminal-speed-up-tablet_kz.png"
								alt={"группы блюд в меню"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
				{
					tabName: "Экосистема",
					desktopTitle: "Меняй за пару кликов",
					desktopContent:
						"Настрой единое меню для продаж в зале, приложении и на сайте за пару кликов.\nСбор статистики продаж и списание со склада производятся по техкарте блюда.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container3
							)}
						>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image3)}
								src="./assets/menu-terminal-ecosystem_kz.png"
								alt={"складской учет в ресторане"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					tabName: "Бәрі өсу үшін",
					desktopTitle: "Сіз бұны еппен сатыпсыз!",
					desktopContent: (
						<p>
							Ең маржиналдық позицияларды жылжыту үшін старт-листтерді пайдаланыңыз. Терминалдағы сыбырлар топпингтер мен модификаторларды ұсынуды кассирдің есіне салады.
						</p>
					),
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container1
							)}
						>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image1)}
								src="./assets/menu-terminal-sell_kz.png"
								alt={"меню кафе на кассовом терминале"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
				{
					tabName: "Жұмысты жеделдету",
					desktopTitle: "Әрқашан оңай таңдау",
					desktopContent:
						"Тапсырыстарды тезірек қалыптастырыңыз - тағамдарды түрлеріне қарай топтастырыңыз және олардың фотосуреттерін қосыңыз. Электрондық таразыны қосыңыз - салмағына қарай немесе бөліктермен сатуды есепке алу автоматты түрде жүргізіледі.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container2
							)}
						>
							<StaticImage
								className={classNames(
									mediaContent.image,
									mediaContent.image2,
									mediaContent.image__desktop
								)}
								src="./assets/menu-terminal-speed-up_kz.png"
								alt={"группы блюд в меню"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(
									mediaContent.image,
									mediaContent.image2,
									mediaContent.image__tablet
								)}
								src="./assets/menu-terminal-speed-up-tablet_kz.png"
								alt={"группы блюд в меню"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
				{
					tabName: "Экожүйе",
					desktopTitle: "Екі кликпен ауыстырыңыз",
					desktopContent:
						"Залда, қосымшада және сайтта сату үшін бірыңғай мәзірді екі кликпен теңшеңіз.\nСату статистикасын жинау және қоймадан есептен шығару тағамның техкартасы бойынша жүргізіледі.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container3
							)}
						>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image3)}
								src="./assets/menu-terminal-ecosystem_kz.png"
								alt={"складской учет в ресторане"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					tabName: "Всё для роста",
					desktopTitle: "Предлагай и продавай",
					desktopContent: (
						<p>
							Используй старт-листы для продвижения самых маржинальных позиций.<br />
							Подсказки на&nbsp;терминале напомнят кассиру предложить топпинги и&nbsp;модификаторы.
						</p>
					),
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container1
							)}
						>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image1)}
								src="./assets/menu-terminal-sell_by.png"
								alt={"меню кафе на кассовом терминале"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
				{
					tabName: "Ускорение работы",
					desktopTitle: "Всегда лёгкий выбор",
					desktopContent:
						"Формируй заказы быстрее, группируя блюда по типам и добавляя их фото. \nПодключи электронные весы: учет продаж на развес/частями ведётся автоматически.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container2
							)}
						>
							<StaticImage
								className={classNames(
									mediaContent.image,
									mediaContent.image2,
									mediaContent.image__desktop
								)}
								src="./assets/menu-terminal-speed-up_by.png"
								alt={"группы блюд в меню"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(
									mediaContent.image,
									mediaContent.image2,
									mediaContent.image__tablet
								)}
								src="./assets/menu-terminal-speed-up-tablet_by.png"
								alt={"группы блюд в меню"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
				{
					tabName: "Экосистема",
					desktopTitle: "Вижу цель, не вижу препятствий",
					desktopContent:
						"Настрой единое меню для продаж в зале, приложении и на сайте за пару кликов.\nСобирай и объединяй статистику продаж по всем каналам. Единое меню — единое \nсписание со склада по техкартам блюд.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container3__by
							)}
						>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image3)}
								src="./assets/menu-terminal-ecosystem_by.png"
								alt={"складской учет в ресторане"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
				},
			] as Array<{
				tabName: string

				// Заголовки могут быть для разных раскладок
				desktopTitle: string
				tabletTitle?: string
				mobileTitle?: string

				// Текстовый контент может быть для разных
				desktopContent: string
				tabletContent?: string
				mobileContent?: string

				detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

				mediaContent: JSX.Element
				backdropContent: JSX.Element
			}>,
		}
	}

	return {
		features: [
			{
				tabName: "Всё для роста",
				desktopTitle: "Пора продавать!",
				desktopContent: (
					<p>
						Используй старт-листы для продвижения самых маржинальных позиций.
						<br />
						Подсказки на&nbsp;терминале напомнят кассиру предложить топпинги
						и&nbsp;модификаторы.
					</p>
				),
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container1
						)}
					>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image1)}
							src="./assets/menu-terminal-sell.png"
							alt={"меню кафе на кассовом терминале"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
			},
			{
				tabName: "Ускорение работы",
				desktopTitle: "Без лишних раздумий",
				desktopContent:
					"Формируй заказы быстрее — сгруппируй блюда по типам и добавь их фото. Подключи \nэлектронные весы — учет продаж на развес или частями будет вестись автоматически.",
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container2_ru
						)}
					>
						<StaticImage
							className={classNames(
								mediaContent.image,
								mediaContent.image2,
								mediaContent.image__desktop
							)}
							src="./assets/menu-terminal-speed-up.png"
							alt={"группы блюд в меню"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(
								mediaContent.image,
								mediaContent.image2,
								mediaContent.image__tablet
							)}
							src="./assets/menu-terminal-speed-up-tablet.png"
							alt={"группы блюд в меню"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
			},
			{
				tabName: "Экосистема",
				desktopTitle: "Вижу цель, не вижу препятствий",
				desktopContent:
					"Настрой единое меню для продаж в зале, приложении и на сайте за пару кликов.\nСбор статистики продаж и списание со склада производятся по техкарте блюда.",
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container3__ru
						)}
					>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image3)}
							src="./assets/menu-terminal-ecosystem.png"
							alt={"складской учет в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={backdropIconsStyles.backdropFigure} />,
			},
		] as Array<{
			tabName: string

			// Заголовки могут быть для разных раскладок
			desktopTitle: string
			tabletTitle?: string
			mobileTitle?: string

			// Текстовый контент может быть для разных
			desktopContent: string
			tabletContent?: string
			mobileContent?: string

			detailLink?: { href: string; useGatsbyLink: boolean; text?: string }

			mediaContent: JSX.Element
			backdropContent: JSX.Element
		}>,
	}
}
