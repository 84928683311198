import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Меню и техкарты",
			desktopTitle: "Сочетание вкуса и прибыли",
			subTitle: (
				<>
					<p className={styles.subtitle_kz_desktop}>
						Анализируй меню и получай больше прибыли. Экосистема
						Quick&nbsp;Resto подскажет, как автоматизирвоать финансовые процессы, <br />
						предоставит статистику приходов и расходов, напомнит <br />
						пополнить запасы.
					</p>
					<p className={styles.subtitle_kz_tablet}>
						Анализируй меню и получай больше прибыли. Экосистема Quick&nbsp;Resto <br />
						подскажет, как автоматизирвоать финансовые процессы, предоставит <br />
						статистику приходов и расходов, напомнит пополнить запасы.
					</p>
					<p className={styles.subtitle_kz_mobile}>
						Анализируй меню и получай больше <br />
						прибыли. Экосистема Quick&nbsp;Resto <br />
						подскажет, как автоматизирвоать <br />
						финансовые процессы, предоставит <br />
						статистику приходов и расходов, <br />
						напомнит пополнить запасы.
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/menu-presentation_kz.png"
					alt="меню для ресторана"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "Мәзір және техкарталар",
			desktopTitle: "Дәм мен пайда үйлесімі",
			subTitle: (
				<>
					<p className={styles.subtitle_kz_desktop}>
						Мәзірді талдаңыз да, көбірек пайда алыңыз. Quick Resto экожүйесі қаржылық процестерді
						қалай автоматтандыруға болатынын көрсетеді, кірістер мен шығыстар статистикасын береді,
						қорларды толтыруды еске салады.
					</p>
					<p className={styles.subtitle_kz_tablet}>
						Мәзірді талдаңыз да, көбірек пайда алыңыз. Quick Resto экожүйесі қаржылық процестерді
						қалай автоматтандыруға болатынын көрсетеді, кірістер мен шығыстар статистикасын береді, қорларды
						толтыруды еске салады.
					</p>
					<p className={styles.subtitle_kz_mobile}>
						Мәзірді талдаңыз да, көбірек пайда алыңыз. Quick Resto экожүйесі қаржылық процестерді
						қалай автоматтандыруға болатынын көрсетеді, кірістер мен шығыстар статистикасын береді, қорларды
						толтыруды еске салады.
					</p>
				</>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін ",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/menu-presentation_kz.png"
					alt="меню для ресторана"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Меню и техкарты",
			desktopTitle: "Баланс вкуса и прибыли",
			subTitle:(
				<>
					<p className={styles.subtitle_kz_desktop}>
						Анализируй меню и получай больше прибыли. <br />
						Экосистема Quick&nbsp;Resto подскажет, как автоматизирвоать <br />
						финансовые процессы, предоставит статистику приходов <br />
						и расходов, напомнит пополнить запасы.
					</p>
					<p className={styles.subtitle_kz_tablet}>
						Анализируй меню и получай больше прибыли. Экосистема Quick&nbsp;Resto <br />
						подскажет, как автоматизирвоать финансовые процессы, предоставит <br />
						статистику приходов и расходов, напомнит пополнить запасы.
					</p>
					<p className={styles.subtitle_kz_mobile}>
						Анализируй меню и получай больше <br />
						прибыли. Экосистема Quick&nbsp;Resto <br />
						подскажет, как автоматизирвоать <br />
						финансовые процессы, предоставит <br />
						статистику приходов и расходов, <br />
						напомнит пополнить запасы.
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/menu-presentation_by.png"
					alt="меню для ресторана"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	return {
		pageName: "Меню и техкарты",
		desktopTitle: "Баланс вкуса и прибыли",
		subTitle:
			"Управляй маржинальностью меню и зарабатывай больше. Экосистема Quick Resto автоматизирует финансовые процессы, контролирует приход и расход, подскажет, когда надо пополнить запасы на складе. ",
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/menu-presentation.png"
				alt="меню для ресторана"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}
