import * as React from "react"
import { SVGProps } from "react"

const TechCardsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14.0013 15.9962C12.812 15.931 11.8677 14.9459 11.8677 13.7402C11.8677 12.4923 12.8793 11.4807 14.1272 11.4807C14.3366 11.4807 14.5395 11.5092 14.732 11.5626C14.962 10.5587 15.8608 9.80981 16.9344 9.80981C18.0081 9.80981 18.9068 10.5587 19.1369 11.5626C19.3293 11.5092 19.5322 11.4807 19.7416 11.4807C20.9895 11.4807 22.0011 12.4923 22.0011 13.7402C22.0011 14.9002 21.1269 15.8561 20.0013 15.9849V19.9996H14.0013V15.9962Z"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M14.0015 14.9995C13.4492 14.9995 13.0015 15.4472 13.0015 15.9995C13.0015 16.5518 13.4492 16.9995 14.0015 16.9995V14.9995ZM16.0015 16.9995C16.5537 16.9995 17.0015 16.5518 17.0015 15.9995C17.0015 15.4472 16.5537 14.9995 16.0015 14.9995V16.9995ZM14.0015 16.9995H16.0015V14.9995H14.0015V16.9995Z"
			fill="#7D828C"
		/>
		<path
			d="M20 6.99976C20 7.55204 20.4477 7.99976 21 7.99976C21.5523 7.99976 22 7.55204 22 6.99976H20ZM11.0032 20.9998C11.5555 20.9998 12.0032 20.552 12.0032 19.9998C12.0032 19.4475 11.5555 18.9998 11.0032 18.9998V20.9998ZM4 4.99976H20V2.99976H4V4.99976ZM4 18.9998V4.99976H2V18.9998H4ZM20 4.99976V6.99976H22V4.99976H20ZM4 20.9998H11.0032V18.9998H4V20.9998ZM2 18.9998C2 20.1043 2.89543 20.9998 4 20.9998V18.9998H2ZM20 4.99976H22C22 3.89519 21.1046 2.99976 20 2.99976V4.99976ZM4 2.99976C2.89543 2.99976 2 3.89519 2 4.99976H4V2.99976Z"
			fill="#7D828C"
		/>
		<path
			d="M11.4194 7.99976L6.2954 7.99976"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.85742 11.9998L6.2954 11.9998"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.85742 15.9998L6.2954 15.9998"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default TechCardsIcon
