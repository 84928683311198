import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import { pagesLinks } from "../../common/links"
import { Locales } from "../../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				title: "Подходит для сетей \nи франшиз",
				description: (
					<p className={styles.appDescription}>
						Каждое новое заведение в твоей сети получает готовое меню, техкарты и
						все настройки автоматизации. А ты получаешь аналитику по заведению и
						можешь управлять правами доступа.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image}
						src="./assets/menu-frenchise-1_kz.png"
						alt="управление сетью ресторанов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature,
				theme: "cyan",
				actionText: "Подробнее",
				actionLink: pagesLinks.franchise.href,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: "Желілер мен франшизаларға\nжарайды",
				description: (
					<p className={styles.appDescription}>
						Желіңіздегі әрбір жаңа мекеме дайын мәзірді, техкарталарды және автоматтандырудың барлық
						теңшелімдерін алады. Ал сіз мекеме бойынша аналитиканы аласыз және қолжеткізу құқықтарын
						басқара аласыз.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image}
						src="./assets/menu-frenchise-1_kz.png"
						alt="управление сетью ресторанов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature,
				theme: "cyan",
				actionText: "Толығырақ",
				actionLink: pagesLinks.franchise.href,
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				title: "Всё для развития \nсети",
				description: (
					<p className={styles.appDescription}>
						Каждое новое заведение в твоей сети получает готовое меню, техкарты и
						все настройки автоматизации. А ты получаешь аналитику по заведению и
						можешь управлять правами доступа.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image}
						src="./assets/menu-frenchise-1_by.png"
						alt="управление сетью ресторанов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature,
				theme: "cyan",
				actionText: "Подробнее",
				actionLink: pagesLinks.franchise.href,
			},
		]
	}

	return [
		{
			title: "Идеально для франшиз и сетей",
			description: (
				<p className={styles.appDescription}>
					Каждое новое заведение в твоей сети получает готовое меню, техкарты и
					все настройки автоматизации. А ты получаешь аналитику по заведению и
					можешь управлять правами доступа.
				</p>
			),
			img: (
				<StaticImage
					className={styles.image}
					src="./assets/menu-frenchise-1.png"
					alt="управление сетью ресторанов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.feature,
			theme: "cyan",
			actionText: "Подробнее",
			actionLink: pagesLinks.franchise.href,
		},
	]
}
